@import '@shared/index.css';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.recharts-symbols:hover {
  cursor: pointer;
  transition: 0.2s;
  d: path('M15,0A15,15,0,1,1,-15,0A15,15,0,1,1,15,0');
}

.matrix-label:hover {
  > line {
    transition: 0.2s;
    stroke-width: 3;
  }
  cursor: pointer;
}
